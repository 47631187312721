<template>
  <v-container id="settings-page" fluid tag="section">
    <base-material-card icon="mdi-cog" :title="$t('settings.Web settings')">
      <v-form
        ref="form"
        v-model="valid"
        class="settings-form"
        @submit.prevent="patchItem"
      >
        <p>{{ $t("settings.Title") }}</p>
        <v-text-field v-model="form.title" value="required"></v-text-field>
        <p>{{ $t("settings.Description") }}</p>
        <v-textarea rows="2" v-model="form.description"></v-textarea>
        <p>{{ $t("settings.Keywords") }}</p>
        <v-textarea rows="2" v-model="form.keywords"></v-textarea>
        <p>{{ $t("settings.Site name") }}</p>
        <v-text-field v-model="form.name" required></v-text-field>
        <p>{{ $t("settings.Site url adress") }}</p>
        <v-text-field v-model="form.url" required></v-text-field>
        <p>{{ $t("settings.Info email adress") }}</p>
        <v-text-field
          v-model="form.email"
          :rules="emailRules"
          required
        ></v-text-field>
        <p>{{ $t("settings.Support Email Adress") }}</p>
        <v-text-field v-model="form.support"></v-text-field>
        <p>{{ $t("settings.Comission when client withdrawal") }}</p>
        <v-text-field
          v-model="form.commission"
          append-icon="mdi-currency-btc"
          required
        ></v-text-field>
        <p>{{ $t("settings.Facebook profile") }}</p>
        <v-text-field v-model="form.facebook" required></v-text-field>
        <p>{{ $t("settings.Twitter profile") }}</p>
        <v-text-field v-model="form.twitter" required></v-text-field>
        <v-btn
          type="submit"
          :disabled="sending || savedItem != null"
          color="success"
          >{{ $t("button.Save") }}</v-btn
        >
      </v-form>
    </base-material-card>
  </v-container>
</template>
<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/settings",
      valid: true,
      form: "",
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: null,

      checkbox: false,
    };
  },
  created() {
    this.get(this.apiQuery).then((data) => {
      this.form = data;
    });
  },

  methods: {
    patchItem() {
      this.patch(this.apiQuery, this.form);
    },
    onSaved(item, index) {
      if (index === -1) {
        this.total++;
        item.isNew = true;
        this.items.push(item);
        window.setTimeout(() => {
          this.$refs.editForm.clearForm();
        }, 1500);
      } else {
        Object.assign(this.items[index], item);
        window.setTimeout(() => {
          this.$refs.editForm.closeForm();
        }, 2500);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<style scoped>
.settings-form {
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
p {
  font-size: 1rem;
  font-weight: bold;
}
</style>
